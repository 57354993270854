// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBK11imJiLbzuPxEhZJNNI9_u6j8ZnjbYk",
  authDomain: "whynot-campout.firebaseapp.com",
  projectId: "whynot-campout",
  storageBucket: "whynot-campout.appspot.com",
  messagingSenderId: "357119600262",
  appId: "1:357119600262:web:05e14d9ade682a65925307",
  measurementId: "G-J06NPQNEC7"
};


// Initialize Firebase
const fbApp = initializeApp(firebaseConfig);
const db = getFirestore(fbApp);

export { fbApp, db };
