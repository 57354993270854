<!-- PasswordPrompt.vue -->
<template>
  <v-container class="fill-height">
    <v-responsive class="align-center fill-height mx-auto">
      <v-img class="mb-0" height="200" src="@/assets/logo.png" />
      <v-text-field
        v-model="password"
        label="Enter Password"
        type="password"
      ></v-text-field>
      <v-btn @click="checkPassword">Submit</v-btn>
    </v-responsive>
  </v-container>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const password = ref("");
const correctPassword = "whyNOT24#%";
const router = useRouter();

const checkPassword = () => {
  if (password.value === correctPassword) {
    localStorage.setItem("isAuthenticated", "true");
    router.push({ name: "Survey Responses" });
  } else {
    alert("Incorrect password");
  }
};
</script>

<style scoped>
/* Your styles here */
</style>
