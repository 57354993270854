<template>
  <v-container>
    <v-responsive>
      <img
        src="@/assets/header.png"
        alt="WHYNOT CAMPOUT BANNER"
        class="header"
      />
    </v-responsive>
  </v-container>
</template>

<script setup></script>

<style scoped>
img {
  width: 100%;
  height: auto;
}
</style>
