/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter, createWebHistory } from 'vue-router/auto'
import { setupLayouts } from 'virtual:generated-layouts'
import { routes } from 'vue-router/auto-routes'
import Index from '@/pages/index.vue' // Adjust the path if necessary
import PostCampoutSurvey from '@/pages/PostCampoutSurvey.vue' // Adjust the path if necessary
import SurveyResponses from '@/pages/SurveyResponses.vue'
import Password from '@/components/Password.vue';


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: setupLayouts([
    ...routes,
    {
      path: '/',
      name: 'index',
      component: Index,
    },
    {
      path: '/post-campout-survey',
      name: 'Post Campout Survey',
      component: PostCampoutSurvey,
    },
      {
    path: '/password-required',
    name: 'Password',
    component: Password,
  },
        {
      path: '/survey-responses',
      name: 'Survey Responses',
      component: SurveyResponses,
          beforeEnter: (to, from, next) => {
      const isAuthenticated = localStorage.getItem('isAuthenticated');
      if (isAuthenticated === 'true') {
        next();
      } else {
        next({ name: 'Password' });
      }
    },
    },
  ]),
})

// Workaround for https://github.com/vitejs/vite/issues/11804
router.onError((err, to) => {
  if (err?.message?.includes?.('Failed to fetch dynamically imported module')) {
    if (!localStorage.getItem('vuetify:dynamic-reload')) {
      console.log('Reloading page to fix dynamic import error')
      localStorage.setItem('vuetify:dynamic-reload', 'true')
      location.assign(to.fullPath)
    } else {
      console.error('Dynamic import error, reloading page did not fix it', err)
    }
  } else {
    console.error(err)
  }
})

router.isReady().then(() => {
  localStorage.removeItem('vuetify:dynamic-reload')
})

export default router
