<template>
  <Suspense>
    <template #default>
      <Responses />
    </template>
    <template #fallback> Loading... </template>
  </Suspense>
</template>

<script setup>
import { defineAsyncComponent } from "vue";

const Display = defineAsyncComponent(() => import("../components/Contact.vue"));
const Responses = defineAsyncComponent(() =>
  import("../components/Responses.vue")
);
</script>
